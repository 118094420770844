import styled, { css } from "styled-components";
import tw from "tailwind-styled-components";

export const Title = tw.h2`
  text-primary-800
  font-extralight
  text-2xl desktop:text-6xl
  mb-4
`;

export const Description = tw.div`
  text-base desktop:text-xl
`;

export const ImageWrapper = styled.div`
  > img {
    object-fit: cover;
    border-radius: var(--radiusL);
    height: 160px;
  }

  @media screen and (min-width: 1128px) {
    margin-top: var(--lineHeightXL);
    width: 360px;
    flex-shrink: 0;

    > img {
      height: auto;
    }
  }
`;

export const RichTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacingS);
  ${({ mobileImageBottom }) =>
    mobileImageBottom && `flex-direction: column-reverse;`}
  @media screen and (min-width: 1128px) {
    ${({ hasImage, imagePlacement }) =>
    hasImage &&
    css`
        align-items: flex-start;
        gap: var(--spacing2Xl);
        flex-direction: ${imagePlacement === "right" ? "row-reverse" : "row"};
      `}
  }
`;
